<template>
  <div>
    <div class="progress">
      <div
        class="time-quantum-container"
        :style="'width: ' + item.widthRate + '%;'"
        v-for="(item, idx) in timeQuantumData"
        :key="idx"
      >
        <el-tooltip
          placement="top"
          :content="item.desc + '：' + item.beginTime + ' ~ ' + item.endTime"
        >
          <div :class="'time-quantum ' + item.css"></div>
        </el-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import { DiffSeconds } from "../until/helper";

export default {
  props: {
    dateOption: {
      type: Array,
    },
  },

  data() {
    return {
      nTotalSeconds: 86400, //一天的总秒数
      nTotalRate: 99, //总比例数
      nFirstWidthRate: 0,
      nCenterWidthRate: 0,
      nLastWidthRate: 0,

      timeQuantumData: [],
    };
  },

  mounted() {
    this.InitProgress();
  },

  methods: {
    async InitProgress() {
      this.CalcRangeWidth();
      this.SetProgress();
    },

    CalcRangeWidth() {
      this.nTotalRate = 99;
      let nCount = this.dateOption.length;

      for (let i = 0; i < nCount; i++) {
        let objInfo = this.dateOption[i];
        let nDiffSeconds = 0;
        let nWidthRate = 0;

        if (i === 0) {
          if (objInfo.beginTime !== "00:00:00") {
            nDiffSeconds = DiffSeconds("00:00:00", objInfo.beginTime);
            this.nFirstWidthRate = this.CalculatePercentage(nDiffSeconds);
          }
        }

        if (i + 1 >= nCount) {
          if (objInfo.endTime !== "23:59:59") {
            nDiffSeconds = DiffSeconds(objInfo.endTime, "23:59:59");
            this.nLastWidthRate = this.CalculatePercentage(nDiffSeconds, false);
          }
        }

        this.nCenterWidthRate =
          this.nTotalRate - (this.nFirstWidthRate + this.nLastWidthRate);
      }
    },

    SetProgress() {
      let nCount = this.dateOption.length;

      for (let i = 0; i < nCount; i++) {
        let objInfo = this.dateOption[i];
        let objInfoNext = null;
        let nDiffSeconds = 0;
        let nWidthRate = 0;
        let arrBeginTime = [];
        let arrEndTime = [];

        //开始时间段的处理
        if (i === 0) {
          arrEndTime = objInfo.beginTime.split(":");
          if (objInfo.beginTime !== "00:00:00") {
            this.timeQuantumData.push({
              widthRate: this.nFirstWidthRate,
              css: "rest",
              desc: "停运",
              beginTime: "00:00",
              endTime: arrEndTime[0] + ":" + arrEndTime[1],
            });
          }
        }

        nDiffSeconds = DiffSeconds(objInfo.beginTime, objInfo.endTime);
        nWidthRate = this.CalculateCenterPercentage(nDiffSeconds);
        this.nCenterWidthRate -= nWidthRate;

        arrBeginTime = objInfo.beginTime.split(":");
        arrEndTime = objInfo.endTime.split(":");

        this.timeQuantumData.push({
          widthRate: nWidthRate,
          css: "work",
          desc: "运营",
          beginTime: arrBeginTime[0] + ":" + arrBeginTime[1],
          endTime: arrEndTime[0] + ":" + arrEndTime[1],
        });

        if (i + 1 < nCount) {
          objInfoNext = this.dateOption[i + 1];
          nDiffSeconds = DiffSeconds(objInfo.endTime, objInfoNext.beginTime);
          nWidthRate = this.CalculateCenterPercentage(nDiffSeconds, i + 1 >= nCount);
          this.nCenterWidthRate -= nWidthRate;

          arrBeginTime = objInfo.endTime.split(":");
          arrEndTime = objInfoNext.beginTime.split(":");

          this.timeQuantumData.push({
            widthRate: nWidthRate,
            css: "rest",
            desc: "停运",
            beginTime: arrBeginTime[0] + ":" + arrBeginTime[1],
            endTime: arrEndTime[0] + ":" + arrEndTime[1],
          });
        } else {
          //结束时间段的处理
          if (objInfo.endTime !== "23:59:59") {
            arrBeginTime = objInfo.endTime.split(":");

            this.timeQuantumData.push({
              widthRate: this.nLastWidthRate,
              css: "rest",
              desc: "停运",
              beginTime: arrBeginTime[0] + ":" + arrBeginTime[1],
              endTime: "23:59",
            });
          }
        }
      }
    },

    CalculatePercentage(nNum, isLast) {
      isLast = typeof isLast !== "undefined" ? isLast : false;

      let nCalcResult = 0;
      let nReturnResult = 0;

      if (isLast) {
        nReturnResult = this.nTotalRate;
      } else {
        nCalcResult = Math.round((nNum / this.nTotalSeconds) * 100);
        nReturnResult = nCalcResult > this.nTotalRate ? this.nTotalRate : nCalcResult;
      }

      return nReturnResult;
    },

    CalculateCenterPercentage(nNum, isLast) {
      isLast = typeof isLast !== "undefined" ? isLast : false;

      let nCalcResult = 0;
      let nReturnResult = 0;

      if (isLast) {
        nReturnResult = this.nCenterWidthRate;
      } else {
        nCalcResult = Math.round((nNum / this.nTotalSeconds) * 100);
        nReturnResult =
          nCalcResult > this.nCenterWidthRate ? this.nCenterWidthRate : nCalcResult;
      }

      return nReturnResult;
    },
  },
};
</script>

<style lang="less" scoped>
.progress {
  width: 100%;
  overflow: hidden;
  margin-top: 17px;
  margin-bottom: 20px;
  clear: both;

  .time-quantum-container {
    height: 15px;
    float: left;

    .time-quantum {
      width: 100%;
      height: 15px;
      overflow: hidden;
      margin: 0;
      padding: 0;
      float: left;
      border-radius: 10px;
      -moz-border-radius: 10px;
      -webkit-border-radius: 10px;
    }
  }

  .work {
    background-color: #03a9f4;
  }

  .rest {
    background-color: #bdbdbd;
  }
}
</style>
