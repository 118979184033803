<template>
  <div class="page-container">
    <el-form :inline="true">
			<el-form-item>
				<el-select
					clearable
					v-model="searchParams.parkId"
					placeholder="请选择园区"
					class="centerSelInput"
				>
					<el-option
						v-for="item in ParkOption"
						:key="item.val"
						:label="item.text"
						:value="item.val"
					>
					</el-option>
				</el-select>
			</el-form-item>

      <el-form-item>
        <el-input clearable v-model="searchParams.carNum" placeholder="请输入车辆编号" class="centerInput "></el-input>
      </el-form-item>

      <el-form-item>
        <el-date-picker
					clearable
					v-model="searchDateRange"
					class="centerInput"
					value-format="yyyy-MM-dd"
          placeholder="请选择日期"
					type="daterange"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
					@change="handleChangeSearchDate"
				></el-date-picker>
      </el-form-item>
      <el-form-item>
        <button class="centerCzbtn" @click="handleSearch">查询</button>
      </el-form-item>
    </el-form>
		
		<el-card class="card-statistics">
			<el-row>
				<el-col class="statistics-col" :span="4">
					<label @click="OpenTotalPlanDrawer">
						今日总计划数: <span>{{ kanbanStatistical.planTotalNum }}</span>
					</label>
					
				</el-col>
				<el-col class="statistics-col" :span="4">
					在线车辆数：<span>{{ kanbanStatistical.onlineCarNum }}</span>
				</el-col>
				<el-col class="statistics-col" :span="6" :offset="2">
					当前时间：<span>{{ searchParams.beginDate }} 至 {{ searchParams.endDate }}</span>
				</el-col>
				<el-col class="statistics-col" :span="4">
					当前排班计划：<span>{{ kanbanStatistical.currentSchedulingPlan }}</span>
				</el-col>
				<el-col class="statistics-col" :span="4">
					实际运营车辆：<span>{{ kanbanStatistical.actualOperationCar }}</span>
				</el-col>
			</el-row>
		</el-card>
		
		<div class="plan-body">
			<el-timeline class="timeline-container" v-if="operatingPlanData.length > 0">
				<template v-for="item in operatingPlanData">
					<el-timeline-item size="large" type="primary" :timestamp="item.date" placement="top">
						<template v-for="car in item.list">
							<el-card class="card-car">
								<el-card class="card-car-info">
									<el-row class="car-info">
										<el-col :span="8">车辆编号：<span>{{ car.carNum }}</span></el-col>
										<el-col :span="8">车辆类型：<span>{{ car.carType }}</span></el-col>
										<el-col :span="8">运营模式：<span>{{ car.practices }}</span></el-col>
									</el-row>
								</el-card>
								
								<template v-for="route in car.routeDetail">
									<el-card class="card-timeline">
										<div class="car-line-name">{{ route.routeName }}</div>
										<div class="car-line-chart">
											<div class="progress-container">
												<div class="progress-item">
													<div class="progress-title">计划</div>
													<div class="progress-content">
														<PlanBillboard :date-option="route.planList"></PlanBillboard>
													</div>
												</div>
												
												<div class="progress-item">
													<div class="progress-title">实际</div>
													<div class="progress-content">
														<PlanBillboard :date-option="route.actualList"></PlanBillboard>
													</div>
												</div>
											</div>
										</div>
									</el-card>
								</template>
							</el-card>
						</template>
					</el-timeline-item>
				</template>
				
				<!--
				<el-timeline-item size="large" type="primary" timestamp="2018/4/12" placement="top">
					<el-card class="card-car">
						<el-card class="card-car-info">
							<el-row class="car-info">
								<el-col :span="8">车辆编号：<span>G3502</span></el-col>
								<el-col :span="8">车辆类型：<span>Sharing-VAN 1.0Plus</span></el-col>
								<el-col :span="8">运营模式：<span>通勤模式</span></el-col>
							</el-row>
						</el-card>
						
						<el-card class="card-timeline">
							<div class="car-line-name">春笋一号线</div>
							<div class="car-line-chart">
								<div class="progress-container">
									<div class="progress-item">
										<div class="progress-title">计划</div>
										<div class="progress-content">
											<PlanBillboard :date-option="planConfig"></PlanBillboard>
										</div>
									</div>
									
									<div class="progress-item">
										<div class="progress-title">实际</div>
										<div class="progress-content">
											<PlanBillboard :date-option="practicalConfig"></PlanBillboard>
										</div>
									</div>
								</div>
							</div>
						</el-card>
						
						<el-card class="card-timeline">
							<div class="car-line-name">春笋二号线</div>
							<div class="car-line-chart">
								<div class="progress-container">
									<div class="progress-item">
										<div class="progress-title">计划</div>
										<div class="progress-content">
											<PlanBillboard :date-option="planConfig"></PlanBillboard>
										</div>
									</div>
									
									<div class="progress-item">
										<div class="progress-title">实际</div>
										<div class="progress-content">
											<PlanBillboard :date-option="practicalConfig"></PlanBillboard>
										</div>
									</div>
								</div>
							</div>
						</el-card>
					</el-card>
					
					<el-card class="card-car">
						<el-card class="card-car-info">
							<el-row class="car-info">
								<el-col :span="8">车辆编号：<span>VIP0001</span></el-col>
								<el-col :span="8">车辆类型：<span>Sharing-VAN 2.0Plus</span></el-col>
								<el-col :span="8">运营模式：<span>VIP模式</span></el-col>
							</el-row>
						</el-card>
						
						<el-card class="card-timeline">
							<div class="car-line-name">春笋一号线</div>
							<div class="car-line-chart">
								<div class="progress-container">
									<div class="progress-item">
										<div class="progress-title">计划</div>
										<div class="progress-content">
											<PlanBillboard :date-option="planConfig"></PlanBillboard>
										</div>
									</div>
									
									<div class="progress-item">
										<div class="progress-title">实际</div>
										<div class="progress-content">
											<PlanBillboard :date-option="practicalConfig"></PlanBillboard>
										</div>
									</div>
								</div>
							</div>
						</el-card>
						
						<el-card class="card-timeline">
							<div class="car-line-name">春笋二号线</div>
							<div class="car-line-chart">
								<div class="progress-container">
									<div class="progress-item">
										<div class="progress-title">计划</div>
										<div class="progress-content">
											<PlanBillboard :date-option="planConfig"></PlanBillboard>
										</div>
									</div>
									
									<div class="progress-item">
										<div class="progress-title">实际</div>
										<div class="progress-content">
											<PlanBillboard :date-option="practicalConfig"></PlanBillboard>
										</div>
									</div>
								</div>
							</div>
						</el-card>
					</el-card>
				</el-timeline-item>
				-->
			</el-timeline>
			<el-empty description="未查询到含有实际运营数据的车辆" v-else></el-empty>
		</div>
	
		<el-drawer
			destroy-on-close
			size="600px"
			class="total-plan-drawer"
			:title="totalPlanDrawer.title"
			:visible.sync="totalPlanDrawer.visible"
			:direction="totalPlanDrawer.direction"
			@closed="CloseTotalPlanDrawer"
		>
			<el-card class="plan-container" v-for="(item, idx) in todayTotalPlanData" :key="idx">
				<div slot="header" class="title">
					<span>{{ item.date }}</span>
				</div>
				<el-card class="card-total-plan" v-for="(plan, i) in item.list" :key="i">
					<div slot="header" class="header">
						<span>{{ plan.carNum }}</span>
					</div>
					
					<template v-for="(info, j) in plan.list">
						<el-row class="plan-list" :gutter="10">
							<el-col class="plan-item" :span="8">
								<div class="title-container">
									<div class="dot green"></div>
									<div class="title">开始时间</div>
								</div>
								<div class="content">{{ item.date }} {{ info.planBeginTime }}</div>
							</el-col>
							<el-col class="plan-item" :span="8">
								<div class="title-container">
									<div class="dot yellow"></div>
									<div class="title">结束时间</div>
								</div>
								<div class="content">{{ item.date }} {{ info.planEndTime }}</div>
							</el-col>
							<el-col class="plan-item" :span="8">
								<div class="title-container">
									<div class="dot-empty"></div>
									<div class="title">安全员</div>
								</div>
								<div class="content">{{ info.userName }}</div>
							
							</el-col>
						</el-row>
						<div class="hr" v-if="(j+1) < plan.list.length">
							<el-divider></el-divider>
						</div>
					</template>
				</el-card>
			</el-card>
		</el-drawer>
  </div>
</template>

<script>
	import PlanBillboard from '../../components/PlanBillboard';
	import {_getParkList} from "../../api/global";
	import {_KanbanPlanList, _KanbanStatistical, _KanbanTodayTotalPlanList} from "../../api/parkOperating";
	import {TimestampToDatetime, TimestampToTime} from "../../until/helper";

	export default {
		components: {
			PlanBillboard,
		},
		
    data() {
      return {
				blPageLoading: null,
	
				ParkOption: [],
	
				searchDateRange: [],
				currentDate: TimestampToDatetime(new Date().getTime()),
	
				searchParams: {
					parkId: "",
					carNum: "",
					beginDate: "",
					endDate: ""
				},
	
				kanbanStatistical: {
					planTotalNum: 0,
					onlineCarNum: 0,
					currentSchedulingPlan: 0,
					actualOperationCar: 0,
				},
	
				operatingPlanData: [],
	
				//今日总计划
				todayTotalPlanData:[],
				
				totalPlanDrawer: {
					visible: false,
					direction: 'rtl',
					title: '',
				},
      };
    },
		
		mounted() {
			this.InitPageData();
		},
		
		methods: {
			async InitPageData(){
				this.searchParams.beginDate = this.currentDate;
				this.searchParams.endDate = this.currentDate;
				this.searchDateRange = [this.currentDate, this.currentDate];
				
				this.OpenPageLoading();
				
				await this.GetParkOption();
				await this.handlePlanList();
				await this.handleGetStatisticalData();
				
				this.ClosePageLoading();
			},
			
			//获取园区选项
			async GetParkOption() {
				let res = await _getParkList();
				
				if (res.code === 0) {
					this.ParkOption = res.data;
				}
			},
			
			async handleSearch(){
				this.OpenPageLoading();
				
				await this.handlePlanList();
				await this.handleGetStatisticalData();
				
				this.ClosePageLoading();
			},
			
			//获取统计数据
			async handleGetStatisticalData(){
				let { data } = await _KanbanStatistical(this.searchParams);
				
				this.kanbanStatistical.actualOperationCar = data.actualOperationCar;
				this.kanbanStatistical.currentSchedulingPlan = data.currentSchedulingPlan;
				this.kanbanStatistical.onlineCarNum = data.onlineCarNum;
				this.kanbanStatistical.planTotalNum = data.planTotalNum;
			},
			
			async handlePlanList(){
				let { data } = await _KanbanPlanList(this.searchParams);
				
				this.operatingPlanData = this.FmtPlanListData(data);
			},
			
			//获取今日总计划列表(弹窗)
			async handleTodalTotalPlanList(){
				this.todayTotalPlanData = [];
				
				let { data } = await _KanbanTodayTotalPlanList(this.searchParams);
				
				this.todayTotalPlanData = data;
			},
			
			handleChangeSearchDate(){
				if (this.searchDateRange.length >= 2){
					this.searchParams.beginDate = this.searchDateRange[0];
					this.searchParams.endDate = this.searchDateRange[1];
				}else{
					this.searchParams.beginDate = this.currentDate;
					this.searchParams.endDate = this.currentDate;
				}
			},
			
			async OpenTotalPlanDrawer(){
				this.totalPlanDrawer.title = '总计划';
				
				this.OpenPageLoading();
				await this.handleTodalTotalPlanList();
				this.ClosePageLoading();
				
				this.totalPlanDrawer.visible = true;
			},
			
			CloseTotalPlanDrawer(){
				this.todayTotalPlanData = [];
				this.totalPlanDrawer.visible = false;
			},
			
			OpenPageLoading() {
				let objOption = {
					lock: true,
					text: '数据加载中',
					background: 'rgba(0, 0, 0, 0.8)',
					spinner: 'el-icon-loading',
					target: '.page-container',
				};
				
				this.blPageLoading = this.$loading(objOption);
			},
			
			ClosePageLoading() {
				this.blPageLoading.close();
			},
			
			//格式化数据
			FmtPlanListData(data){
				let FmtData = [];
				
				for (const datum of data) {
					let carList = {
						date: datum.date,
						list: [],
					};
					
					for (const car of datum.list) {
						let periodList = {
							carType: car.carType,
							carNum: car.carNum,
							practices: car.practices,
							routeDetail: []
						};
						
						for (const period of car.routeDetail) {
							let actualList = [];
							
							for (const actual of period.actualList) {
								actualList.push({
									beginTime: actual.actualStartTime,
									endTime: actual.actualEndTime,
								});
							}
							
							let planList = [];
							
							for (const plan of period.planList) {
								planList.push({
									beginTime: plan.startTime,
									endTime: plan.endTime,
								});
							}
							
							periodList.routeDetail.push({
								routeName: (period.routeName) ? period.routeName : '(未知线路)',
								actualList: actualList,
								planList: planList,
							});
						}
						
						carList.list.push(periodList);
					}
					
					FmtData.push(carList);
				}
				
				return FmtData;
			},
		}
  }
</script>

<style lang="less" scoped>
	/deep/.el-card__body{padding: 0;}
	/deep/.el-timeline-item__timestamp {font-size: 16px; font-weight: bold; color: #03a0e7;}
	/deep/.el-timeline-item__node {margin-top: 1px;}
	/deep/.el-card__header{padding: 0;}
	/deep/.el-drawer__header{font-size: 20px; color: #444; font-weight: bold;}
	.el-range-editor{border: 0;}
	/deep/.el-range-separator{color: #C0C4CC !important;}
	.el-timeline {padding-left: 5px;}
	.el-divider{padding: 0; margin: 0; background-color: #EBEEF5}
	
	.card-statistics{
		padding: 15px;
		
		.statistics-col{
			font-size: 14px; color: #333; text-align: center;
			
			label{border-bottom: 2px solid #d5d5d5; padding: 0 4px 4px 4px; cursor: pointer;}
			span{font-weight: bold;}
		}
		
		.can-click{
		
		}
	}
	
	.plan-container{
		padding: 5px 20px 20px 20px; margin-bottom: 20px;
		
		.title{
			padding: 10px 0; margin: 0; font-size: 20px; color: #666; font-weight: bold;
		}
		
		.card-total-plan{
			margin-top: 20px;
			
			.header{padding: 10px; font-size: 16px; color: #666;}
			
			.plan-list{
				padding: 10px;
				
				.plan-item{
					font-size: 14px; color: #666;
					
					.title-container{
						width: 95px; overflow: hidden; padding: 5px 5px 5px 20px; margin: 0 auto;
						
						.dot{
							width: 9px; height: 9px; border: 1px solid #fff; float: left; margin-top: 13px;
							border-radius: 50%; -webkit-border-radius: 50%; -moz-border-radius: 50%; box-shadow: 1px 1px 5px rgba(0, 0, 0, 1) ;
						}
						.dot-empty{width: 15px; height: 9px; float: left;}
						
						.green{background-color: #00c06f;}
						.yellow{background-color: #d38f0b;}
						
						.title{width: 70px; float: left; margin-left: 8px; font-size: 14px; color: #666; font-weight: bold;}
					}
					
					.content{width: 100%; padding: 5px; text-align: center;}
				}
			}
			
			.hr{
				padding: 10px 20px; overflow: hidden;
			}
		}
	}
	
	
	.plan-body {
		width: 100%; overflow: hidden; margin-top: 15px;
		
		.card-date-slider{
			padding: 3px 40px 20px 40px; margin-bottom: 10px;
		}
		
		.timeline-container{
			.card-car{
				padding: 20px; margin-bottom: 15px;
				
				.card-car-info{
					padding: 10px;
					
					.car-info{
						font-size: 14px; color: #333; text-align: center;
						
						span{
							font-weight: bold;
						}
					}
				}
				
				.card-timeline {
					padding: 15px;
					
					.car-line-name{
						font-size: 16px; font-weight: bold;
					}
					
					.progress-container {
						width: 100%; overflow: hidden;
						
						.progress-item {
							width: 100%; overflow: hidden;
							
							.progress-title {
								width: 6%; height: 20px; line-height: 20px; margin-top: 15px; font-size: 14px; color: #333;
								text-align: center; float: left;
							}
							
							.progress-content {
								width: 94%; overflow: hidden; float: left;
							}
						}
					}
				}
				
			}
		}
	}
	
  .todayPlan {
    height: 36px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0px 12px;
    color: #666666;
    font-size: 14px;
  }

  .three3 {
    color: #333;
    font-size: 14px;
    font-weight: 550;
  }

  .todayPlan>div {
    display: flex;
    align-items: center;
  }

  .todayPlan>div>div:last-child,
  .vehNumber>div:last-child {
    margin-left: 40px;
  }

  .todayPlan>div>div:nth-child(2),
  .vehNumber>div:nth-child(2) {
    margin-left: 40px;
  }

  .vehNumber {
    display: flex;
    height: 36px;
    background: #F5F8FA;
    line-height: 36px;
    font-size: 14px;
    color: #666;
  }

  .time {
    background-color: #fff;
    height: 65px;
    line-height: 65px;
    font-size: 12px;
    color: #999;
  }

  .time>span:first-child {
    margin-left: 70px;
  }

  .time>span:last-child {
    margin-left: 500px;
  }

  .lineCon {
    background-color: #fff;
  }

  .line {
    font-size: 12px;
    color: #666;
    display: flex;
    justify-content: space-around;
    padding: 20px 0px 11px;

  }

  .actual {
    margin-top: 16px;
  }

  .plan,
  .actual {
    height: 28px;
    border-top: 1px solid #D4D7D9;
    border-bottom: 1px solid #D4D7D9;
    position: relative;
    font-size: 12px;
    color: #666;
    line-height: 28px;
    display: flex;
  }

  .planBefore {
    width: 1px;
    height: 14px;
    background: #459CE0;
    position: absolute;
    left: 0px;
    top: 9px;
  }

  .plan span,
  .actual span {
    margin-left: 10px;
  }

  .planBlue1,
  .planBlue2 {
    background-color: #1683D9;
    color: white;
    overflow: hidden;
  }

  .planYellow1,
  .planYellow2 {
    background-color: #D0844D;
    color: white;
    overflow: hidden;
    position: absolute;
    margin-left: 125px;
  }

  .planYellow2 {
    position: absolute;
    right: 0px;
  }

  .planBlue1 {
    margin-left: 90px;
  }

  .planBlue2 {
    position: absolute;
    right: 0px;
  }
</style>
