import service from '@/until/api'

export function _KanbanStatistical(data) {
	return service({
		url: '/report-data/planBoard/statistic',
		method: 'POST',
		data
	})
}

export function _KanbanPlanList(data) {
	return service({
		url: '/report-data/planBoard/compare/detail',
		method: 'POST',
		data,
	})
}

export function _KanbanTodayTotalPlanList(data) {
	return service({
		url: '/report-data/planBoard/list/detail',
		method: 'POST',
		data,
	})
}
